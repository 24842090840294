import React, { Component } from 'react';

import WIP from "../workInProgress";

class ModularKitchens extends Component {
  render(){
    return (
        <div className="fluid-container text-center">
            <WIP/>
        </div>
    );
  }
}

export default ModularKitchens;
